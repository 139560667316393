import { Outlet } from 'react-router-dom';
import Error401Page from 'src/pages/401';
import Error404Page from 'src/pages/404';
import Error500Page from 'src/pages/500';
// import { authRoutes } from './auth';
import { authDemoRoutes } from './auth-demo';
import { dashboardRoutes } from './dashboard';
import { shopifyRoutes } from './shopifyRoutes';
import { lazy } from 'react';

import { Layout as DashboardLayout } from 'src/layouts/dashboard';
const HomePage = lazy(() => import('src/pages/dashboard/index'));
const LoginByToken = lazy(() => import('src/pages/dashboard/LoginByToken'));
const ShopifyCallBack = lazy(() => import('src/pages/ShopifyCallBack'));
const ShopifyRedirect = lazy(() => import('src/pages/ShopifyRedirect'));
const ShopifyPayment = lazy(() => import('src/pages/ShopifyPayment'));

export const routes = [
   {
      element: <Outlet />,
      children: [
         {
            index: true,
            element: (
               <DashboardLayout>
                  <HomePage />
               </DashboardLayout>
            ),
         },
      ],
   },
   // ...authRoutes,
   ...authDemoRoutes,
   ...dashboardRoutes,
   ...shopifyRoutes,
   {
      path: '401',
      element: <Error401Page />,
   },
   {
      path: '404',
      element: <Error404Page />,
   },
   {
      path: '500',
      element: <Error500Page />,
   },
   {
      path: '*',
      element: <Error404Page />,
   },
   {
      path: 'login-by-token',
      element: <LoginByToken />,
   },
   {
      path: 'api/shopify/app',
      element: <ShopifyCallBack />,
   },
   {
      path: 'api/shopify/app/:server_id',
      element: <ShopifyCallBack />,
   },
   {
      path: 'merchant/shopify/callback',
      element: <ShopifyRedirect />,
   },
   {
      path: 'merchant/shopify/callback/:server_id',
      element: <ShopifyRedirect />,
   },
   {
      path: 'merchant/shopify/payment',
      element: <ShopifyPayment />,
   },
   {
      path: 'merchant/shopify/payment/:server_id/:migration_id',
      element: <ShopifyPayment />,
   },
];
