import axios from 'src/utils/instantAxios.js';

export const demoMigrationServices = {
   get: {
      getCartType: () => axios.get('/api/create-migration/setup-cart-type'),
      getRecentMigration: (payload) => axios.get(`/api/migration/recent/${payload}`),
      getSmartUpdate: (payload) =>
         axios.get(`/api/migration/recent/${payload}`, {
            params: { update: true },
         }),
      getRemigration: (payload) => axios.get(`/api/migration/remigrate/${payload}`),
      getProgress: (payload) => axios.get(`api/migration/migration_app/${payload}`),
      startDemoMigration: (payload) =>
         axios.get(`api/create-migration/start-demo-migration/${payload}`),
      startFullMigration: (payload) => axios.get(`api/start-full-migration/${payload}`),
      getMigrationNotice: (payload) => axios.get(`api/migration/notice-migration/${payload}`),
      resumeMigration: (payload) => axios.get(`api/migration/resume-migration/${payload}`),
      restartMigration: (payload) => axios.get(`api/migration/restart-migration/${payload}`),
      stopMigration: (payload) => axios.get(`api/migration/stop-migration/${payload}`),
      applyCoupon: (payload) => axios.get('api/apply-coupon', { params: { ...payload } }),
      reloadMapping: (payload) => axios.get(`api/create-migration/reload-map/${payload}`),
      demoAgain: (payload) => axios.get(`api/create-migration/reconfig-target-store/${payload}`),
   },
   post: {
      createMigration: (payload) => axios.post(`/api/create-migration/setup-cart`, payload),
      createConfiguration: (payload) =>
         axios.post(`/api/create-migration/config-cart`, {
            ...payload,
         }),
      createOrder: (payload) => axios.post('/api/paypal/basic-create-order', { ...payload }),
      captureOrder: (payload) => axios.post('/api/paypal/basic-capture-order', { ...payload }),
      checkConnector: (payload) => axios.post(`api/check-connector`, { ...payload }),
      updateEntities: (payload) =>
         axios.post(`api/create-migration/update-entities`, { ...payload }),
      uploadFile: (payload) => axios.post(`api/create-migration/upload-file`, payload), // Because the payload is a form-data, and it can not be cloned
   },
};
