import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import DefaultSlide from 'src/pages/dashboard/CustomComponents/DefaultSlide';
import { Stack, Typography } from '@mui/material';
import { LinearProgressWithLabel } from 'src/pages/dashboard/CustomComponents/ProgressWithLabel';

function MigratingItem(props) {
   const { notice, mir, index, isFinish, migrationTypeIndex } = props;

   const renderIcon = (index) => {
      if (isFinish || migrationTypeIndex > index) return <CheckCircleIcon color='primary' />;

      if (migrationTypeIndex === index) return <PlayCircleOutlineIcon color='primary' />;

      return <RadioButtonUncheckedIcon color='primary' />;
   };

   const renderText = (text) => {
      if (text === 'cartrules') return 'Cart Rules';
      if (text === 'creditmemo') return 'Credit Memo';
      return text?.charAt(0)?.toUpperCase() + text?.slice(1);
   };

   const getPercent = (mir) =>
      (parseInt(notice?.process?.[mir]?.imported) / parseInt(notice?.process?.[mir]?.total)) *
         100 || 0;

   if (!notice?.config?.[mir]) return null;

   return (
      <DefaultSlide index={index} transition={50}>
         <Stack direction='column' key={mir}>
            <Stack direction='row' alignItems='center'>
               {renderIcon(index)}
               <Typography variant='subtitle2' sx={{ marginRight: '10px' }}>
                  Migrating {renderText(mir)}
               </Typography>
            </Stack>
            {migrationTypeIndex === index && !isFinish && (
               <LinearProgressWithLabel value={getPercent(mir)} />
            )}
         </Stack>
      </DefaultSlide>
   );
}

export default MigratingItem;
