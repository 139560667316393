import React from 'react';
import DefaultGrow from '../../CustomComponents/DefaultGrow';
import { Card, CardContent, CardHeader, Divider, Stack, Typography } from '@mui/material';
import PrepareIcon from '../Common/PrepareIcon';
import MigratingItem from '../FullMigration/RenderComponents/MigratingItem';
import CompleteItem from '../FullMigration/RenderComponents/CompleteItem';
import CustomLoading from '../../CustomComponents/CustomLoading';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const defaultMigrations = [
   'manufacturers',
   'categories',
   'attributes',
   'products',
   'customers',
   'quotes',
   'newsletters',
   'orders',
   'invoice',
   'shipment',
   'creditmemo',
   'reviews',
   'pages',
   'blogs',
   'coupons',
   'cartrules',
];

function DemoProcess(props) {
   const { data, errorMessage, migrationId } = props;

   const migrationStatus = data?.status_migration;

   const notice = data?.notice;

   const migrationAction = notice?.resume?.action;
   const isFinish = notice?.finish;
   const migrationType = notice?.resume?.type;
   const migrationTypeIndex = defaultMigrations.indexOf(migrationType);

   const navigate = useNavigate();

   useEffect(() => {
      if (isFinish || migrationStatus === 4)
         setTimeout(navigate(`/migration/${migrationId}`), 2000);
      // eslint-disable-next-line
   }, [isFinish, migrationId]);

   if (isEmpty(data) && !errorMessage)
      return <CustomLoading loadingText="Loading Migration Progress" />;

   if (errorMessage)
      return (
         <Typography variant="subtitle2" textAlign="center">
            {errorMessage}!
         </Typography>
      );

   return (
      <React.Fragment>
         <DefaultGrow>
            <Card>
               <CardHeader title="Migration Progress" />
               <Divider />

               <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Stack spacing={1} width="60%">
                     {/* Preparing */}
                     <Typography variant="subtitle2" sx={{ display: 'flex' }}>
                        <PrepareIcon isFinish={isFinish} migrationAction={migrationAction} />
                        Preparing Your Migration
                     </Typography>

                     {/* Migrating components */}
                     {defaultMigrations.map((mir, index) => (
                        <MigratingItem
                           key={mir}
                           notice={notice}
                           mir={mir}
                           index={index}
                           isFinish={isFinish}
                           migrationTypeIndex={migrationTypeIndex}
                        />
                     ))}

                     <CompleteItem isFinish={isFinish} index={defaultMigrations.length} />
                  </Stack>
               </CardContent>
            </Card>
         </DefaultGrow>
      </React.Fragment>
   );
}

export default DemoProcess;
