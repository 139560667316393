import { styled } from '@mui/material/styles';
import {
   Autocomplete,
   Grid,
   Popper,
   TableCell,
   TextField,
   Tooltip,
   autocompleteClasses,
   gridClasses,
   tableCellClasses,
   tooltipClasses,
   inputClasses,
   Stack,
   Paper,
   FormControl,
} from '@mui/material';

const CustomAutoComplete = styled(Autocomplete)(({ theme }) => ({
   '& .MuiInputBase-root': {
      padding: '0 16px',
      height: '40px',
   },
}));

const CustomFormControl = styled(FormControl)(() => ({
   '& .MuiSelect-select': {
      padding: '8px 20px',
   },
}));

const CustomInput = styled(TextField)(({ theme }) => ({
   '& .MuiInputBase-input': {
      padding: '0 16px',
      height: '40px',
   },
   '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: '#f0f0f0',
   },
   [`& .${inputClasses.multiline}`]: {
      padding: '7px 0',
      minHeight: '24px',
   },
}));

const CustomSmallInput = styled(TextField)(({ theme }) => ({
   '& .MuiInputBase-input': {
      padding: '0 16px',
      height: '35px',
   },
   '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: '#f0f0f0',
   },
}));

const CustomInputUpload = styled(TextField)(({ theme }) => ({
   '& .MuiInputBase-input': {
      padding: '5px 16px',
      height: '25px',
   },
   '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: '#f0f0f0',
   },
}));

const CustomPopper = styled(Popper)({
   [`& .${autocompleteClasses.paper}`]: {
      boxSizing: 'border-box',
      margin: '2px 0',
      border: '1px solid #cccccc',
      borderRadius: '10px',
   },
   [`& .${autocompleteClasses.listbox}`]: {
      padding: 0,
   },
});

const CustomTableCell = styled(TableCell)(({ theme }) => ({
   [`&.${tableCellClasses.head}`]: {
      fontWeight: 800,
   },
   [`&.${tableCellClasses.body}`]: {
      padding: 8,
   },
}));

const CustomTableCellSmall = styled(TableCell)(({ theme }) => ({
   [`&.${tableCellClasses.head}`]: {
      fontWeight: 800,
   },
   [`&.${tableCellClasses.body}`]: {
      padding: 4,
      fontWeight: 500,
   },
}));

const CustomTableCellUpload = styled(TableCell)(({ theme }) => ({
   [`&.${tableCellClasses.head}`]: {
      fontWeight: 800,
   },
   [`&.${tableCellClasses.body}`]: {
      padding: '8px 15px',
      fontWeight: 500,
   },
}));

const CustomGridItem = styled(Grid)(({ theme }) => ({
   [`&.${gridClasses.item}`]: {
      padding: '16px 16px',
   },
}));

const CustomGridCenter = styled(Grid)(({ theme }) => ({
   [`&.${gridClasses.item} .MuiTypography-subtitle1`]: {
      textAlign: 'center',
   },
   [`&.${gridClasses.item} .MuiAutocomplete-root`]: {
      padding: '0 8px',
   },
}));

const CustomTableCellFontWeigth = styled(TableCell)(({ theme }) => ({
   [`&.${tableCellClasses.head}`]: {
      fontWeight: 800,
   },
   [`&.${tableCellClasses.body}`]: {
      paddingTop: 8,
      paddingBottom: 8,
      fontWeight: 500,
   },
}));

const HtmlTooltip = styled(({ className, ...props }) => (
   <Tooltip
      {...props}
      classes={{ popper: className }}
      placement="bottom"
      PopperProps={{
         modifiers: [
            {
               name: 'flip',
               options: {
                 rootBoundary: 'document',
               },
             },
         ],
      }}
   />
))(({ theme }) => ({
   [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 500,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
   },
}));

const StackItem = styled(Stack)(({ theme }) => ({
   textAlign: 'center',
   justifyContent: 'center',
   a: {
      display: 'block',
      whiteSpace: 'nowrap',
      textDecoration: 'none',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: '100%',
      fontSize: '14px',
   },
}));

const CustomMigrationItem = styled(Paper)(({ theme }) => ({
   ...theme.typography.body2,
   textAlign: 'center',
   justifyContent: 'center',
   // color: theme.palette.text.secondary,
   borderRadius: 'none',
   boxShadow: 'none',
   a: {
      display: 'block',
      whiteSpace: 'nowrap',
      textDecoration: 'none',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: '100%',
   },
}));

export {
   CustomAutoComplete,
   CustomPopper,
   CustomInput,
   CustomTableCell,
   CustomTableCellSmall,
   CustomGridItem,
   CustomTableCellFontWeigth,
   CustomSmallInput,
   HtmlTooltip,
   StackItem,
   CustomMigrationItem,
   CustomGridCenter,
   CustomFormControl,
   CustomInputUpload,
   CustomTableCellUpload,
};
