import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Seo } from 'src/components/seo';
import { useSettings } from 'src/hooks/use-settings';
import CustomStepper from './Common/CustomStepper';
import MigrationInstruction from '../MigrationIntruction/MigrationInstruction';
import ConfirmDetail from './ConfirmMigration/ConfirmDetail';
import { useAuth } from 'src/hooks/use-auth';
import { useLocation } from 'react-router-dom';
import { migrationServices } from 'src/api/services/migration';
import DemoInclude from './DemoMigration/DemoInclude';
import DemoCoupon from './DemoMigration/DemoCoupon';
import { cloneDeep } from 'lodash';
import DemoBalanceReminder from './DemoMigration/DemoBalanceReminder';
import ConfirmActions from './ConfirmMigration/ConfirmActions';
import SmartWarning from './ConfirmMigration/SmartWarning';
import ConfirmHeaderNote from './ConfirmMigration/ConfirmComps/ConfirmHeaderNote';
import { useAlert } from 'src/hooks/useAlert';

function ConfirmMigration() {
   const settings = useSettings();

   const { pathname } = useLocation();
   const migrationId = pathname?.split('/')[2]; //To get id from the URL
   const updateType = pathname.includes('recent') ? 'recent' : 'smart';

   const alert = useAlert();

   const [instructionKey, setInstructionKey] = useState('total_entities');
   const [migrationData, setMigrationData] = useState({});
   const [loading, setLoading] = useState(false);
   const [smartWarning, setSmartWarning] = useState(false);

   const header = updateType.includes('smart')
      ? 'Next Step: Smart Update Data Migration'
      : updateType.includes('recent')
      ? 'Next Step: Recent Data Migration'
      : 'Next Step:';

   const { initialize } = useAuth();

   const afterHover = (key) => {
      if (!key) return;
      setInstructionKey(() => key);
   };

   const getOrderMigration = async () => {
      setLoading((current) => !current);

      try {
         await migrationServices.get.getOrderMigration(migrationId).then((res) => {
            if (res.data.success) {
               res.data.data.order.entities = JSON.parse(res.data.data.order.entities);
               res.data.data.order.options = JSON.parse(res.data.data.order.options);
               // These 2 fields were stringified, so we have to parse them into JSON
               setMigrationData(() => res.data.data);
            } else {
               alert('error', res.data.message);
            }
         });
      } catch (error) {
         console.log(error);
         alert('error', 'Can Not Get Migration');
      }

      setLoading((current) => !current);
   };

   useEffect(() => {
      if (migrationId) initialize();

      getOrderMigration();
      // eslint-disable-next-line
   }, []);

   const afterApplyCoupon = (data) => {
      let _data = cloneDeep(migrationData);

      _data.order.discount_amount = data.discount_amount;
      _data.order.subtotal = data.subtotal;
      _data.order.total = data.total;

      setMigrationData(() => _data);
   };

   return (
      <React.Fragment>
         <Seo title="Confirm Migration" />
         <Box
            component="main"
            sx={{
               flexGrow: 1,
               py: 8,
            }}
         >
            <Container maxWidth={settings.stretch ? false : 'xl'}>
               <Grid
                  container
                  spacing={{
                     xs: 3,
                     lg: 4,
                  }}
               >
                  {/* Step */}
                  <CustomStepper index={1} stepType={updateType} />

                  {/* Content */}
                  <Grid item xs={9}>
                     <Stack spacing={3}>
                        {/* Header */}
                        <Typography variant="h4">{header}</Typography>

                        {/* Entities and Options */}
                        <ConfirmHeaderNote updateType={updateType} />
                        <ConfirmDetail
                           afterHover={afterHover}
                           data={migrationData}
                           getOrderMigration={getOrderMigration}
                        />

                        {/* Included */}
                        <DemoInclude data={migrationData} afterHover={afterHover} />

                        {/* Coupon */}
                        <DemoCoupon
                           migrationId={migrationId}
                           afterApplyCoupon={afterApplyCoupon}
                           loading={loading}
                        />

                        {/* Balance Reminder */}
                        <DemoBalanceReminder data={migrationData} />

                        {/* Warning */}
                        {updateType === 'smart' && (
                           <SmartWarning setSmartWarning={setSmartWarning} />
                        )}

                        {/* Actions */}
                        <ConfirmActions
                           data={migrationData}
                           loading={loading}
                           updateType={updateType}
                           smartWarning={smartWarning}
                        />
                     </Stack>
                  </Grid>

                  {/* Instruction */}
                  <Grid item xs={3}>
                     <MigrationInstruction stickyTop="7.25rem" instructionKey={instructionKey} />
                  </Grid>
               </Grid>
            </Container>
         </Box>
      </React.Fragment>
   );
}

export default ConfirmMigration;
