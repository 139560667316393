import { Card, CardContent, CardHeader, Divider, Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import DefaultGrow from 'src/pages/dashboard/CustomComponents/DefaultGrow';
import { useState } from 'react';
import { demoMigrationServices } from 'src/api/services/demoMigration';
import UpdateButton from '../ConfirmComps/ConfirmUpdateButton';
import ConfirmEntitiesTable from '../ConfirmComps/ConfirmEntitiesTable';
import { entities } from 'src/locales/constant/arrayRender';
import { useAlert } from 'src/hooks/useAlert';
import { useMigrationId } from 'src/hooks/useMigrationId';
import { useSettings } from 'src/hooks/use-settings';

const initialValues = {
   reviews: true,
   coupons: true,
   blogs: true,
   pages: true,
   taxes: true,
   manufacturers: true,
   categories: true,
   orders: true,
   customers: true,
   products: true,
};

function ConfirmEntities(props) {
   const { afterHover, data, getOrderMigration } = props;

   const totalEntitiesPrice = data?.order?.entities_price;
   const entities_select = data?.config_notice?.entities_select;

   const renderEntities =
      entities_select && Array.isArray(entities_select) && entities_select?.length !== 0
         ? entities.filter((entity) => entities_select?.includes(entity.field))
         : entities.filter((entity) => data?.config_notice?.[entity.field]);

   const totalEntities = entities_select?.reduce(
      (prev, current) => prev + (data?.order?.entities[current] || 0),
      0
   );

   const alert = useAlert();

   const settings = useSettings();

   const migrationId = useMigrationId(2);

   const [hasChanged, setHasChanged] = useState(false);
   const [loading, setLoading] = useState(false);

   const submit = async (values) => {
      let _payload = {
         migration_id: migrationId,
         recent_entities: [],
      };

      setLoading((current) => !current);
      try {
         const recentEntities = renderEntities
            .filter((entity) => values[entity.field])
            .map((entity) => entity.field);

         _payload.recent_entities = recentEntities;

         await demoMigrationServices.post.updateEntities(_payload).then(async (res) => {
            if (res.data.success) {
               setHasChanged(() => false);
               alert('success', 'Update Entities Success');
               await getOrderMigration();
            }
         });
      } catch (error) {
         console.log(error);
         alert('error', 'Update Entities Fail');
      }
      setLoading((current) => !current);
   };

   return (
      <React.Fragment>
         <Grid item xs={12} md={4}>
            <Formik initialValues={initialValues} onSubmit={(values) => submit(values)}>
               {(formik) => (
                  <Form style={{ height: '100%' }}>
                     <DefaultGrow>
                        <Card
                           sx={{ height: '100%' }}
                           onMouseEnter={() => afterHover('total_entities')}
                        >
                           {/* Header */}
                           <CardHeader
                              title={
                                 <Typography variant="h5">
                                    Total Entity: {totalEntities}(${totalEntitiesPrice})
                                 </Typography>
                              }
                              sx={{
                                 background: (theme) => {
                                    return settings.paletteMode === 'light'
                                       ? theme.palette.primary.light
                                       : theme.palette.primary.darkest;
                                 },
                              }}
                           />

                           <Divider />
                           {/* Content */}
                           <CardContent sx={{ padding: 1 }}>
                              {/* Table */}
                              <ConfirmEntitiesTable
                                 data={data}
                                 setHasChanged={setHasChanged}
                                 renderEntities={renderEntities}
                              />
                              {/* Button */}
                              {hasChanged && (
                                 <UpdateButton hasChanged={hasChanged} loading={loading} />
                              )}
                           </CardContent>
                        </Card>
                     </DefaultGrow>
                  </Form>
               )}
            </Formik>
         </Grid>
      </React.Fragment>
   );
}

export default ConfirmEntities;
