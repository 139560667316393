import axios from 'src/utils/instantAxios.js';

export const profileServices = {
   get: {
      getProfileInformation: () => axios.get('/api/profile'),
      getProfileOrderHistory: () => axios.get('/api/profile/order'),
      getProfilePayment: () => axios.get('/api/profile/billing'),
   },
   post: {
      updateProfile: (payload) => axios.post('/api/auth/update', { ...payload }),
      changePassword: (payload) => axios.post(`/api/profile/change-password`, { ...payload }),
   },
};
