import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SvgIcon } from '@mui/material';
import ShoppingCart01Icon from 'src/icons/untitled-ui/duocolor/shopping-cart-01';
import Truck01Icon from 'src/icons/untitled-ui/duocolor/truck-01';
import { tokens } from 'src/locales/tokens';
import PlusIcon from '@untitled-ui/icons-react/build/esm/Plus';
import ListIcon from '@untitled-ui/icons-react/build/esm/List';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { paths } from 'src/paths';
// import { useLocation } from 'react-router-dom';

export const useSectionsMenu = () => {
   const { t } = useTranslation();
   // const location = useLocation();

   return useMemo(() => {
      return [
         {
            items: [
               {
                  title: t(tokens.nav.myMigration),
                  path: paths.dashboard.index,
                  // disabled: ['/', '/dashboard'].includes(location.pathname),
                  icon: (
                     <SvgIcon fontSize="small">
                        <ListIcon />
                     </SvgIcon>
                  ),
               },
               {
                  title: t(tokens.nav.createMigration),
                  path: paths.dashboard.createMigration,
                  icon: (
                     <SvgIcon fontSize="small">
                        <PlusIcon />
                     </SvgIcon>
                  ),
               },
               {
                  title: t(tokens.nav.allIn),
                  path: paths.dashboard.allInOne,
                  icon: (
                     <SvgIcon fontSize="small">
                        <Truck01Icon />
                     </SvgIcon>
                  ),
               },
               {
                  title: 'Additional Services',
                  path: paths.dashboard.customMigration,
                  icon: (
                     <SvgIcon fontSize="small">
                        <ShoppingCart01Icon />
                     </SvgIcon>
                  ),
               },
            ],
         },
         {
            subheader: 'Help Center',
            items: [
               {
                  title: 'Help Center',
                  icon: (
                     <SvgIcon fontSize="small">
                        <HelpOutlineIcon />
                     </SvgIcon>
                  ),
                  items: [
                     {
                        title: 'Report a Problem',
                        path: 'https://litextension.com/contact-us.html',
                        external: true,
                     },
                     {
                        title: 'FAQs',
                        path: 'https://litextension.com/faq/',
                        external: true,
                     },
                     {
                        title: 'Payment Guide',
                        path: 'https://litextension.com/download/payment_guide.pdf',
                        external: true,
                     },
                     {
                        title: 'My Support Tickets',
                        path: 'https://litextension.zendesk.com/hc/en-us/requests',
                        external: true,
                     },
                  ],
               },
            ],
         },
      ];
   }, [t]);
};
