import PropTypes from 'prop-types';
import { useSettings } from 'src/hooks/use-settings';
import { useSectionsMenu } from './config';
import { HorizontalLayout } from './horizontal-layout';
import { VerticalLayout } from './vertical-layout';
import { withAuthGuard } from 'src/hocs/with-auth-guard';
import React from 'react';
import ZendeskScript from '../zendeskScript/ZendeskScript';
// import Error404Page from 'src/pages/404';

export const Layout = withAuthGuard((props) => {
   const settings = useSettings();
   const sections = useSectionsMenu();

   // const loginFromShopify = JSON.parse(localStorage.getItem('loginFromShopify'));

   // if (loginFromShopify) return <Error404Page />;

   const renderLayout = () => {
      if (settings.layout === 'horizontal')
         return <HorizontalLayout sections={sections} navColor={settings.navColor} {...props} />;

      return <VerticalLayout sections={sections} navColor={settings.navColor} {...props} />;
   };

   return (
      <React.Fragment>
         <ZendeskScript />

         {renderLayout()}
      </React.Fragment>
   );
});

Layout.propTypes = {
   children: PropTypes.node,
};
