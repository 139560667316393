import { createContext, useCallback, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { authApi } from 'src/api/auth';
import { Issuer } from 'src/utils/auth';
import cookies from 'src/api/services/cookies';
import { useNavigate } from 'react-router-dom';
import instance from 'src/utils/instantAxios';
import { paths } from 'src/paths';
import { useAlert } from 'src/hooks/useAlert';

const STORAGE_KEY = 'accessToken';

var ActionType;
(function (ActionType) {
   ActionType['INITIALIZE'] = 'INITIALIZE';
   ActionType['SIGN_IN'] = 'SIGN_IN';
   ActionType['SIGN_UP'] = 'SIGN_UP';
   ActionType['SIGN_OUT'] = 'SIGN_OUT';
})(ActionType || (ActionType = {}));

const initialState = {
   isAuthenticated: false,
   isInitialized: false,
   user: null,
};

const handlers = {
   INITIALIZE: (state, action) => {
      const { isAuthenticated, user } = action.payload;

      return {
         ...state,
         isAuthenticated,
         isInitialized: true,
         user,
      };
   },
   SIGN_IN: (state, action) => {
      const { user } = action.payload;

      return {
         ...state,
         isAuthenticated: true,
         user,
      };
   },
   SIGN_UP: (state, action) => {
      const { user } = action.payload;

      return {
         ...state,
         isAuthenticated: true,
         user,
      };
   },
   SIGN_OUT: (state) => ({
      ...state,
      isAuthenticated: false,
      user: null,
   }),
};

const reducer = (state, action) =>
   handlers[action.type] ? handlers[action.type](state, action) : state;

export const AuthContext = createContext({
   ...initialState,
   issuer: Issuer.JWT,
   signIn: () => Promise.resolve(),
   signUp: () => Promise.resolve(),
   signOut: () => Promise.resolve(),
   initialize: () => Promise.resolve(),
   signInByToken: () => Promise.resolve(),
   signInByShopify: () => Promise.resolve(),
   signInByShopifyCallBack: () => Promise.resolve(),
   initializing: false,
});

export const AuthProvider = (props) => {
   const { children } = props;
   const [state, dispatch] = useReducer(reducer, initialState);
   const navigate = useNavigate();

   const [initializing, setInitializing] = useState(false);

   const queryParameters = new URLSearchParams(window.location.search);
   const returnTo = queryParameters.get('returnTo');

   const alert = useAlert();

   const initialize = useCallback(async () => {
      setInitializing((current) => !current);
      try {
         const accessToken = cookies.get('access_token');
         if (accessToken) {
            const user = await authApi.me({ accessToken });

            dispatch({
               type: ActionType.INITIALIZE,
               payload: {
                  isAuthenticated: true,
                  user,
               },
            });
         } else {
            dispatch({
               type: ActionType.INITIALIZE,
               payload: {
                  isAuthenticated: false,
                  user: null,
               },
            });
         }
      } catch (err) {
         console.error(err);
         dispatch({
            type: ActionType.INITIALIZE,
            payload: {
               isAuthenticated: false,
               user: null,
            },
         });
      }
      setInitializing((current) => !current);
   }, [dispatch]);

   useEffect(
      () => {
         initialize();
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
   );

   const signIn = useCallback(
      async (email, password) => {
         const { accessToken, message } = await authApi.signIn({ email, password });

         // Set header of the axios immediately
         if (accessToken) {
            instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

            const user = await authApi.me({ accessToken });

            dispatch({
               type: ActionType.SIGN_IN,
               payload: {
                  isAuthenticated: true,
                  user,
               },
            });
            window.location.href = returnTo || paths.dashboard.index;

            /**
             * We could use replace here but basically it just changes the url but not redirecting the route. Read for detail:
             * @link https://www.geeksforgeeks.org/difference-between-window-location-href-window-location-replace-and-window-location-assign-in-javascript/
             */
            // window.location.replace(returnTo ?? paths.dashboard.index);
         }
         if (message) {
            alert('error', message);
         }
      },
      // eslint-disable-next-line
      [dispatch, returnTo]
   );

   const signInByToken = useCallback(
      async (accessToken) => {
         if (accessToken) {
            instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

            const user = await authApi.me({ accessToken });
            cookies.set('access_token', accessToken, { path: '/' });

            dispatch({
               type: ActionType.SIGN_IN,
               payload: {
                  isAuthenticated: true,
                  user,
               },
            });

            navigate('/');
         }
      },
      // eslint-disable-next-line
      [dispatch]
   );

   const signInByShopify = useCallback(
      async (accessToken) => {
         if (accessToken) {
            instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
            const user = await authApi.me({ accessToken });

            cookies.set('access_token', accessToken, { path: '/' });

            dispatch({
               type: ActionType.SIGN_IN,
               payload: {
                  isAuthenticated: true,
                  user,
               },
            });

            const href = localStorage.getItem('target_shop').split('.')[0];

            window.location.href = `https://admin.shopify.com/store/${href}/apps/${process.env?.REACT_APP_SHOPIFY_APP_URL}`;
         }
      },
      // eslint-disable-next-line
      [dispatch]
   );

   const signInByShopifyCallBack = useCallback(
      async (accessToken, nextMigrationId, host) => {
         if (accessToken) {
            instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
            const user = await authApi.me({ accessToken });

            cookies.set('access_token', accessToken, { path: '/' });
            cookies.set('shopify_host', host, { path: '/' });

            const shopifyHostParam = `?host=${host}`;

            dispatch({
               type: ActionType.SIGN_IN,
               payload: {
                  isAuthenticated: true,
                  user,
               },
            });
            if (nextMigrationId) {
               if (nextMigrationId === 'list_migration')
                  navigate(`/app-shopify/migration-list${shopifyHostParam}`);
               else navigate(`/app-shopify/full-migration/${nextMigrationId}${shopifyHostParam}`);
            } else navigate(`/app-shopify${shopifyHostParam}`);
         }
      },
      // eslint-disable-next-line
      [dispatch]
   );

   const signUp = useCallback(
      async (email, name, password) => {
         const { accessToken } = await authApi.signUp({ email, name, password });
         const user = await authApi.me({ accessToken });

         sessionStorage.setItem(STORAGE_KEY, accessToken);

         dispatch({
            type: ActionType.SIGN_UP,
            payload: {
               user,
            },
         });
      },
      [dispatch]
   );

   const signOut = useCallback(async () => {
      cookies.remove('access_token');
      dispatch({
         type: ActionType.SIGN_OUT,
         payload: {
            isAuthenticated: false,
         },
      });
   }, [dispatch]);

   return (
      <AuthContext.Provider
         value={{
            ...state,
            issuer: Issuer.JWT,
            signIn,
            signUp,
            signOut,
            initialize,
            signInByToken,
            initializing,
            signInByShopify,
            signInByShopifyCallBack,
         }}
      >
         {children}
      </AuthContext.Provider>
   );
};

AuthProvider.propTypes = {
   children: PropTypes.node.isRequired,
};

export const AuthConsumer = AuthContext.Consumer;
